import { DialogContent, DialogTitle } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectUser, setUserCity } from "../../slices/userSlice"
import Cookies from "js-cookie"
import isEmpty from "lodash/isEmpty"
import {
  StyledBox,
  StyledDialog,
  StyledImg,
  StyledStack,
  StyledStackLeft,
  SubTitle,
  Title,
} from "./styles"
import MumbaiIcon from "../../assets/Mumbai.svg"
import BengaluruIcon from "../../assets/Bengaluru.svg"

interface IModalProps {
  open: boolean
  setShowCitySelectionModal: React.Dispatch<React.SetStateAction<boolean>>
}

const CityModal = ({ open, setShowCitySelectionModal }: IModalProps) => {
  const { userCity } = useAppSelector(selectUser)
  const dispatch = useAppDispatch()

  const handleSelectCity = (city: string) => {
    dispatch(setUserCity({ city }))
    Cookies.set("city", city, { expires: 7 })
    setShowCitySelectionModal(false)
  }

  const handleClose = () => {
    if (isEmpty(userCity)) {
      dispatch(setUserCity({ city: "Bengaluru" }))
      Cookies.set("city", "Bengaluru", { expires: 7 })
      setShowCitySelectionModal(false)
    } else {
      setShowCitySelectionModal(false)
    }
  }

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="city-dialog-title"
      aria-describedby="city-dialog-description"
    >
      <DialogTitle id="update-dialog-title">
        <Title>Choose your city</Title>
        <br />
      </DialogTitle>
      <DialogContent>
        <StyledBox>
          <StyledStackLeft onClick={() => handleSelectCity("Bengaluru")}>
            <StyledImg src={BengaluruIcon} alt="bengaluru-icon" />
            <SubTitle>Bengaluru</SubTitle>
          </StyledStackLeft>
          <StyledStack onClick={() => handleSelectCity("Mumbai")}>
            <StyledImg src={MumbaiIcon} alt="mumbai-icon" />
            <SubTitle>Mumbai</SubTitle>
          </StyledStack>
        </StyledBox>
      </DialogContent>
    </StyledDialog>
  )
}

export default CityModal
