import { createTheme } from "@mui/material"
declare module "@mui/material/styles/createPalette" {
  interface PaletteOptions {
    neutrals: string[]
  }
  interface Palette {
    neutrals: string[]
  }
}

const theme = createTheme({
  palette: {
    primary: {
      light: "#FFFAEE",
      main: "#ffcf4b",
      dark: "#ffc421",
      contrastText: "#263238",
    },
    secondary: {
      main: "#f9f9f9",
      dark: "#cec6c6",
      contrastText: "#65624C",
    },
    success: {
      light: "#00AE6C1A",
      main: "#00ab12",
      contrastText: "#FFFFFF",
    },
    error: {
      light: "#FFC6C3",
      main: "rgb(186, 26, 26)",
      contrastText: "#FFFFFF",
    },
    background: {
      default: "#EFF0F2",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#263238",
      secondary: "#65624C",
      disabled: "rgba(30, 27, 22, 0.38)",
    },
    neutrals: [
      "#333333",
      "#4F4F4F",
      "#828282",
      "#B7B6B8",
      "#E0E0E0",
      "#E5DDDD",
      "#747474",
      "#f2b304",
      "#CAC7C7",
      "#FF3B30",
      "#FFC6C3",
      "#FFF7DF",
      "#FEFBFB",
    ],
  },
  typography: {
    fontFamily: "Inter",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
})

export default theme
