import { Box, Dialog } from "@mui/material"
import { Stack, styled } from "@mui/system"
import theme from "../../theme"

export const StyledBox = styled(Box)`
  display: flex;
  justify-content: center;
  gap: 20px;
  @media (max-width: 500px) {
    gap: 10px;
  }
  @media (max-width: 370px) {
    gap: 10px;
  }
`
export const StyledStackLeft = styled(Stack)`
  display: flex;
  justify-content: flex-end;
  background-color: ${theme.palette.neutrals[12]};
  padding: 25px 40px;
  border-radius: 12px;
  border: 1px solid ${theme.palette.text.secondary};
  cursor: pointer;
  @media (max-width: 500px) {
    padding: 15px 20px;
  }
  @media (max-width: 370px) {
    padding: 2px 8px;
  }
`
export const StyledStack = styled(Stack)`
  display: flex;
  justify-content: flex-end;
  background-color: ${theme.palette.neutrals[12]};
  padding: 25px;
  border-radius: 12px;
  border: 1px solid ${theme.palette.text.secondary};
  cursor: pointer;
  @media (max-width: 500px) {
    padding: 15px;
  }
  @media (max-width: 370px) {
    padding: 5px;
  }
`

export const StyledDialog = styled(Dialog)``

export const StyledImg = styled("img")`
  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    width: 100px;
  }
`

export const Title = styled("span")`
  font-size: 22px;
  font-weight: 600;
`

export const SubTitle = styled("span")`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
  color: ${theme.palette.neutrals[6]};
  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    font-size: 18px;
    font-weight: 500;
  }
`
